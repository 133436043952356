




































































































import Vue from 'vue'
import api, { ApiResponse } from '../../../../api'

export default Vue.extend({
  data() {
    return {
      invalidToken: false,
      passwordChanged: false,
      loading: false,
      form: {
        password: '',
        confirmation: ''
      }
    }
  },

  async created() {
    api
      .verifyPasswordResetToken(
        this.$route.query.token as string,
        this.$route.query.email as string
      )
      .then((response: ApiResponse) => {
        if (!response.isSuccessful) {
          this.invalidToken = true
        }
      })
  },

  methods: {
    onSubmit() {
      this.loading = true
      api
        .resetPassword({
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.form.password
        })
        .then((response: ApiResponse) => {
          this.loading = false

          if (response.isSuccessful) {
            this.passwordChanged = true
          } else {
            //
          }
        })
    }
  }
})
